<template>
   <section class="login-content">
         <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-6  content-left">
                              <div class="p-3">
                                 <h2 class="mb-2 ">Reset Password</h2>
                                 <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                 <form @submit.prevent="onSubmit()">
                                    <div class="row">
                                       <div class="col-lg-12">
                                          <div class="floating-label form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.email.$error ? 'is-invalid' : ''}`" 
                                                v-model="email" 
                                                type="email" 
                                                placeholder="" 
                                                @input="$v.email.$touch()" 
                                                @blur="$v.email.$touch()"
                                             >
                                             <label>Email</label>
                                             <div class="invalid-feedback">
                                                <span v-if="!$v.email.email">Email inválido</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Reset</button>
                                 </form>
                              </div>
                           </div>
                           <div class="col-lg-6 content-right">
                              <img src="@/assets/images/logo.svg" class="img-fluid image-right" alt="">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
    name:'RecoverPassword',
    mixins: [validationMixin],
    validations: {
      email: { required, email }
    },
    data() {
      return {
         email: ''
      }
    },
    methods:{
       onSubmit()
       {
         this.$v.$touch();
         if(!this.$v.$invalid) {
            this.$router.push({name:'auth.login'})
         }
       }
    }
}
</script>